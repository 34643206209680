
import { defineComponent, reactive, ref } from 'vue'
import { IComObj } from '@/global/types'
import httpRequest from '@/service'

export default defineComponent({
  name: '上位法',
  props: {
    lawId: {
      type: String,
      default: ''
    }
  },
  setup() {
    const loading = ref(false)
    const cache: any = {}
    const hostLawList = reactive<IComObj>({
      total: 0,
      data: []
    })
    const hostLawListApi = (lawId: string) => {
      hostLawList.data = []
      hostLawList.total = 0
      if (cache[lawId]) {
        hostLawList.data = cache[lawId].data.hostLaw
        hostLawList.total = cache[lawId].data.hostLawTotal
        return
      }
      loading.value = true
      httpRequest
        .post({
          url: 'hostLawList',
          params: { lawId: lawId }
        })
        .then((res) => {
          hostLawList.data = res.data.hostLaw
          hostLawList.total = res.data.hostLawTotal
          cache[lawId] = res
          loading.value = false
          // if (hostLawList.total === 0) {
          //   ElMessage({
          //     message: '当前法律暂无上位法',
          //     customClass: 'focus-message',
          //     type: 'info'
          //   })
          // }
        })
        .catch((err) => {
          loading.value = false
          console.log(err)
        })
    }

    const hostLawClick = (lawId: string) => {
      hostLawListApi(lawId)
    }
    return { hostLawClick, hostLawList, hostLawListApi, loading }
  }
})
