
import { defineComponent, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import httpRequest from '@/service'
// import { deleteAccurate } from '@/utils/common'
import { IComObj } from '@/global/types'
import HistoryList from './cpns/historyList.vue'
import HostlowList from './cpns/hostlowList.vue'
import NatureSlot from './cpns/natureSlot.vue'
import handleBox from './cpns/handleBox.vue'
import toolBox from './cpns/toolBox.vue'
import { checkToGo } from '@/utils/permission'
export default defineComponent({
  name: '列表页',
  components: {
    NatureSlot,
    HostlowList,
    HistoryList,
    handleBox,
    toolBox
  },
  setup() {
    const store = useStore()
    const searchToolbox = ref();

    watch(
      () => store.state.form,
      () => {
        effectState.flag = Boolean(!store.state.form.effectId)
        paginationState.currentPage = 1
        listDataState.getData()
        totalState.getData()
      },
      {
        deep: true
      }
    )

    // 效力级别更多切换
    const effectState = reactive({
      flag: Boolean(!store.state.form.effectId),
      handleClick(obj: any) {
        const searchList = store.state.searchList
        const value = {
          type: 'effectId',
          name: '效力级别：' + obj.type,
          id: obj.id
        }
        const flag = searchList.some((item: any) => {
          if (item.type === value.type && item.name === value.name) {
            return true
          }
        })
        if (flag) return
        const formValue = { ...store.state.form, effectId: value.id }
        // deleteAccurate(searchList, value)
        searchList.push(value)
        store.commit('setSearchList', searchList)
        store.commit('setForm', formValue)
      }
    })
    // 升序降序
    const sortState = reactive({
      id: '1',
      data: [],
      getData() {
        httpRequest
          .post({
            url: 'sortList'
          })
          .then((res) => {
            sortState.data = res.data
            listDataState.getData()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick: (value: string) => {
        sortState.id = value
        listDataState.getData(true)
      },
      setVal: (value: string) => {
        sortState.id = value;
      }
    })
    sortState.getData()
    // 总体数量
    const totalState = reactive({
      total: 0,
      getData() {
        httpRequest
          .post({
            url: 'searchListTotalList',
            params: { ...store.state.form }
          })
          .then((res) => {
            totalState.total = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    totalState.getData()

    // 分页
    const paginationState = reactive({
      currentPage: 1,
      num: 0,
      pageTotal: 0,
      prevText: '上一页',
      nextText: '下一页',
      handleCurrentChange: (val: number) => {
        paginationState.currentPage = val
        listDataState.getData()
      }
    })

    // 列表数据
    const listDataState = reactive<IComObj>({
      loading: true,
      flag: false,
      data: [],
      searchData: [],
      getData: (ignoreDefaultSort?: boolean) => {
        const _this = listDataState
        _this.loading = true
        if(!ignoreDefaultSort) {
          // 判断标题、正文、字号是否有值有则按照相似度排序，否则按发布日期排序
          if ((!store.state.form.searchTitle || store.state.form.searchTitle.length === 0)
            && (!store.state.form.searchArtical || store.state.form.searchArtical.length === 0)
            && (!store.state.form.postValue || store.state.form.postValue.length === 0)) {
            sortState.setVal("1");
          } else {
            sortState.setVal("0");
          }
        }
        const params = {
          currentPage: paginationState.currentPage,
          pageSize: store.state.form.effectId ? 20 : 5,
          sort: sortState.id,
          ...store.state.form
        }
        // 为了修复bug,后续需要找到根源在哪
        if(params.effectId){
          params.effectIds = params.effectId.split(',')
          delete params.effectId
        }
        httpRequest
          .post({
            url: 'searchListLawList',
            params: params
          })
          .then((res) => {
            checkedState.checkedAmount = []
            checkedState.checkAmount = []
            checkedState.allFlag = false
            if (!res.data.length) {
              _this.flag = true
              _this.searchData = []
            } else {
              // 如果不带查询条件，则不显示子集（id长度大于2的）/2024-04-18改动：后端数据结构调整，不再需要前端过滤id长度大于2
              // if(!params.effectId){
              //   res.data = res.data.filter(item => item.id && item.id.length < 3)
              // }
              res.data.forEach((item: any) => {
                item.list.forEach((i: any) => {
                  i.flag = false
                  checkedState.checkAmount.push(i.id)
                })
              })
              _this.data = res.data
              _this.searchData = res.customData
              _this.flag = false
              paginationState.pageTotal = res.data[0].pageTotal

              // 每次调取接口都将页面回到顶部
              document.documentElement.scrollTop = 0
            }
            _this.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })

    // 全选
    const checkedState = reactive({
      allFlag: false,
      checkAmount: [] as IComObj[],
      checkedAmount: [] as IComObj[],
      handleCheckAll: (val: boolean) => {
        checkedState.allFlag = val
        if (val) {
          checkedState.checkedAmount = JSON.parse(
            JSON.stringify(checkedState.checkAmount)
          )
          listDataState.data.forEach((item: any) => {
            item.list.forEach((i: any) => {
              i.flag = true
            })
          })
        } else {
          checkedState.checkedAmount = []
          listDataState.data.forEach((item: any) => {
            item.list.forEach((i: any) => {
              i.flag = false
            })
          })
        }
      },
      handleCheckSingle: (i: any) => {
        let checkedIndex = checkedState.checkedAmount.indexOf(i.id)
        if (checkedIndex == -1 && i.flag) {
          checkedState.checkedAmount.push(i.id)
          i.flag = true
        } else {
          checkedState.checkedAmount.splice(checkedIndex, 1)
          i.flag = false
        }

        checkedState.allFlag =
          checkedState.checkedAmount.length === checkedState.checkAmount.length
      }
    })

    // 更多跳转
    const detailJump = (m: string) => {
      return m.split("id='")[1].split("'>")[0]
    }

    return {
      listDataState,
      totalState,
      paginationState,
      effectState,
      sortState,
      checkedState,
      detailJump,
      checkToGo
    }
  }
})
