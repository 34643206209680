
import { defineComponent, nextTick, reactive, ref, watch } from 'vue'
import httpRequest from '@/service'
import { useStore } from 'vuex'
import { ILeftPaneState } from '../../types'
import conBox from './cpns/conBox.vue'
import list from './cpns/list.vue'
import tree from './cpns/tree.vue'

export default defineComponent({
  name: 'LeftPane',
  components: {
    conBox,
    list,
    tree
  },
  setup() {
    const store = useStore()
    watch(
      () => store.state.form,
      (newV, oldV) => {
        if (newV.institutionId && newV.institutionId !== oldV.institutionId)
          return
            ;
        [fbjgState.defaultId, fbjgState.level] = [
          store.state.form.institutionId,
          store.state.form.institutionLevel
        ]
        fbjgState.getData()
      },
      {
        deep: true
      }
    )
    watch(
      () => store.state.form,
      (newV, oldV) => {
        if (newV.rulesId && newV.rulesId !== oldV.rulesId) return
          ;
        [fgflState.defaultId, fgflState.level] = [
          store.state.form.rulesId,
          store.state.form.rulesLevel
        ]
        fgflState.getData()
      },
      {
        deep: true
      }
    )
    const showTree = ref(true)
    watch(
      () => store.state.form,
      (newV, oldV) => {
        if (newV.areaId && newV.areaId !== oldV.areaId) return
          ;
        [xzqhState.defaultId, xzqhState.level] = [
          store.state.form.areaId,
          store.state.form.areaLevel
        ]
        // getData()是为了重新获取括号后面的数字值，但是在展开的时候会导致无限递归，
        // 所以每次先注销组件重新渲染一下
        showTree.value = false
        nextTick(() => {
          showTree.value = true
          xzqhState.getData()
        })
      },
      {
        deep: true
      }
    )
    watch(
      () => store.state.form,
      () => {
        ;[
          xljbState.defaultId,
          fbjgState.defaultId,
          fbjgState.level,
          sxxState.defaultId,
          fbnfState.defaultId
        ] = [
          store.state.form.effectId,
          store.state.form.institutionId,
          store.state.form.institutionLevel,
          store.state.form.timelinessId,
          store.state.form.releaseYear
        ]
        xljbState.getData()
        sxxState.getData()
        fbnfState.getData()
      },
      {
        deep: true
      }
    )

    // 点击每一项，需要判断检索栏中是否已有该类别
    const searchListAction = (obj: any) => {
      let arr = store.state.searchList
      let typeFlag = arr.some((item: any) => {
        if (item.type === obj.type) {
          return true
        }
      })
      let nameFlag = arr.some((item: any) => {
        if (item.name === obj.name) {
          return true
        }
      })
      if (typeFlag && !nameFlag) {
        arr.splice(
          arr.findIndex((i: any) => obj.type === i.type),
          1
        )
        arr.push(obj)
        store.commit('setSearchList', arr)
      } else if (typeFlag && nameFlag) {
        return
      } else if (!typeFlag) {
        arr.push(obj)
        store.commit('setSearchList', arr)
      }
    }
    // 顺序：效力级别、发布机关、时效性、法规分类、行政区划、发布年份

    // 效力级别
    const xljbState: ILeftPaneState = reactive({
      title: '效力级别',
      key: 'effectId',
      data: [],
      defaultId: store.state.form.effectId,
      getData() {
        // if (this.data && this.data.length > 0) {
        //   return
        // }
        httpRequest
          .post({ url: 'effectLevelList', params: store.state.form })
          .then((res: any) => {
            let datas: any[] = res.data
            let childrenDataMap: { [key: string]: any[] } = {}
            datas.forEach(item => {
              if (item.parentId) {
                childrenDataMap[item.parentId] = childrenDataMap[item.parentId] || []
                childrenDataMap[item.parentId].push(item)
              } else {
                childrenDataMap.root = childrenDataMap.root || []
                childrenDataMap.root.push(item)
              }
              // if (item.id.length === 4) {
              //   let pid = item.id.substring(0, 2)
              //   childrenDataMap[pid] = childrenDataMap[pid] || []
              //   childrenDataMap[pid].push(item)
              // } else if (item.id.length === 2) {
              //   childrenDataMap.root = childrenDataMap.root || []
              //   childrenDataMap.root.push(item)
              // }
            })
            xljbState.data = datas.filter(item => {
              item.leaf = !childrenDataMap[item.id]
              item.children = childrenDataMap[item.id]
              return !item.parentId
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        xljbState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.effectId = obj.id
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    xljbState.getData()

    // 发布机关
    const fbjgRef = ref()
    const fbjgState: any = reactive({
      title: '发布机关',
      key: 'institutionId',
      keyLevel: 'institutionLevel',
      data: [],
      url: 'institutionList',
      defaultId: store.state.form.institutionId,
      level: store.state.form.institutionLevel,
      parentId: '',
      nextLevel: '',
      loading: true,
      getData() {
        fbjgState.loading = true
        const form = JSON.parse(JSON.stringify(store.state.form))
        form.institutionId = ''
        form.institutionLevel = ''
        httpRequest
          .post({
            url: 'institutionList',
            params: form
          })
          .then((res: any) => {
            fbjgState.data = res.data
            fbjgState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        const filterObj = JSON.parse(JSON.stringify(store.state.form))
        filterObj.institutionId = fbjgState.defaultId = obj.id
        filterObj.institutionLevel = fbjgState.level = obj.level
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    fbjgState.getData()

    // 时效性
    const sxxState: ILeftPaneState = reactive({
      title: '时效性',
      key: 'timelinessId',
      data: [],
      defaultId: store.state.form.timelinessId,
      getData() {
        httpRequest
          .post({ url: 'timelinessList', params: store.state.form })
          .then((res: any) => {
            sxxState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        sxxState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.timelinessId = obj.id
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    sxxState.getData()

    // 法规分类
    const fgflState: ILeftPaneState = reactive({
      title: '法规分类',
      key: 'rulesId',
      keyLevel: 'rulesLevel',
      data: [],
      url: 'rulesList',
      defaultId: store.state.form.rulesId,
      level: store.state.form.rulesLevel,
      parentId: '',
      nextLevel: '',
      loading: true,
      getData() {
        fgflState.loading = true
        const form = JSON.parse(JSON.stringify(store.state.form))
        form.rulesId = ''
        form.rulesLevel = ''
        httpRequest
          .post({
            url: 'rulesList',
            params: form
          })
          .then((res: any) => {
            fgflState.data = res.data
            fgflState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        const filterObj = JSON.parse(JSON.stringify(store.state.form))
        filterObj.rulesId = fgflState.defaultId = obj.id
        filterObj.rulesLevel = fgflState.level = obj.level
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    fgflState.getData()

    // 行政区划
    const xzqhState: any = reactive({
      title: '行政区划',
      key: 'areaId',
      keyLevel: 'areaLevel',
      data: [],
      url: 'areaList',
      defaultId: store.state.form.areaId,
      level: store.state.form.areaLevel,
      parentId: '',
      nextLevel: '',
      loading: true,
      getData() {
        xzqhState.loading = true
        const form = JSON.parse(JSON.stringify(store.state.form))
        form.areaId = ''
        form.areaLevel = ''
        httpRequest
          .post({
            url: 'areaList',
            params: form
          })
          .then((res: any) => {
            xzqhState.data = res.data
            xzqhState.level = xzqhState.data[0].level
            xzqhState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        const filterObj = JSON.parse(JSON.stringify(store.state.form))
        filterObj.areaId = xzqhState.defaultId = obj.id
        filterObj.areaLevel = xzqhState.level = obj.level
        filterObj.leafArea = obj.leaf && obj.level !== '01'
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    xzqhState.getData()

    // 发布年份
    const fbnfState: ILeftPaneState = reactive({
      title: '发布年份',
      key: 'releaseYear',
      data: [],
      defaultId: store.state.form.releaseYear,
      getData() {
        httpRequest
          .post({ url: 'yearList', params: store.state.form })
          .then((res: any) => {
            fbnfState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        fbnfState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.releaseYear = obj.id
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    fbnfState.getData()

    return {
      xljbState,
      fbjgRef,
      fbjgState,
      sxxState,
      fgflState,
      xzqhState,
      fbnfState,
      showTree
    }
  }
})
