import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30f960ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, { height: "100%" }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.data, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(['item', { active: _ctx.state.defaultId === item.id }]),
              key: item.id,
              onClick: ($event: any) => (_ctx.handleItemClick(item))
            }, [
              _createElementVNode("span", null, _toDisplayString(item.name), 1 /* TEXT */),
              _createElementVNode("span", null, "（" + _toDisplayString(item.value.toLocaleString()) + "）", 1 /* TEXT */)
            ], 10 /* CLASS, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" 分页 "),
    _createCommentVNode(" <pagination :data=\"state.data\" :curPage=\"curPageNumber\" @change=\"handlePageClick\"></pagination> ")
  ]))
}