import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "slot-content" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "bottom",
    width: 760,
    trigger: "click",
    "popper-class": "popper-slot-nature"
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, { class: "click-button" }, {
        default: _withCtx(() => [
          _createTextVNode("属性信息")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (it, ind) => {
          return (_openBlock(), _createElementBlock("li", { key: ind }, [
            _createElementVNode("span", null, _toDisplayString(it.name), 1 /* TEXT */),
            _createElementVNode("span", {
              title: it.value
            }, _toDisplayString(it.value), 9 /* TEXT, PROPS */, _hoisted_2)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }))
}