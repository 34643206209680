import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-main" }
const _hoisted_2 = { class: "m-content" }
const _hoisted_3 = { class: "m-left" }
const _hoisted_4 = { class: "m-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_left_pane = _resolveComponent("left-pane")!
  const _component_right_pane = _resolveComponent("right-pane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bread_crumb),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_left_pane)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_right_pane)
      ])
    ])
  ]))
}