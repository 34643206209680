import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LeftPane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree = _resolveComponent("tree")!
  const _component_con_box = _resolveComponent("con-box")!
  const _component_list = _resolveComponent("list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 效力级别 "),
    _createVNode(_component_con_box, {
      title: _ctx.xljbState.title,
      slotH: _ctx.xljbState.data.length
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          lazy: false,
          state: _ctx.xljbState,
          onChange: _ctx.xljbState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "slotH"]),
    _createCommentVNode(" 发布机关 "),
    _createCommentVNode(" <con-box :title=\"fbjgState.title\" :slotH=\"10\">\n      <template v-slot>\n        <tree\n          ref=\"fbjgRef\"\n          :state=\"fbjgState\"\n          @change=\"fbjgState.handleItemClick\"\n        ></tree>\n      </template>\n    </con-box> "),
    _createCommentVNode(" 时效性 "),
    _createVNode(_component_con_box, {
      title: _ctx.sxxState.title,
      slotH: _ctx.sxxState.data.length
    }, {
      default: _withCtx(() => [
        _createVNode(_component_list, {
          state: _ctx.sxxState,
          onChange: _ctx.sxxState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "slotH"]),
    _createCommentVNode(" 法规分类 "),
    _createCommentVNode(" <con-box :title=\"fgflState.title\" :slotH=\"10\">\n      <template v-slot>\n        <tree :state=\"fgflState\" @change=\"fgflState.handleItemClick\"></tree>\n      </template>\n    </con-box> "),
    _createCommentVNode(" 行政区划 "),
    _createVNode(_component_con_box, {
      title: _ctx.xzqhState.title,
      slotH: 10
    }, {
      default: _withCtx(() => [
        (_ctx.showTree)
          ? (_openBlock(), _createBlock(_component_tree, {
              key: 0,
              state: _ctx.xzqhState,
              onChange: _ctx.xzqhState.handleItemClick
            }, null, 8 /* PROPS */, ["state", "onChange"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createCommentVNode(" 发布年份 "),
    _createVNode(_component_con_box, {
      title: _ctx.fbnfState.title,
      slotH: _ctx.fbnfState.data.length
    }, {
      default: _withCtx(() => [
        _createVNode(_component_list, {
          state: _ctx.fbnfState,
          onChange: _ctx.fbnfState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "slotH"])
  ]))
}