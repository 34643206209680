import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-33f71920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dropdown-link" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_download = _resolveComponent("download")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    class: "select",
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDowmload(String(_ctx.currentI.id))))
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_download)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createTextVNode("下载pdf ")
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDowmload(String(_ctx.currentI.id), 'docx')))
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_download)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createTextVNode("下载word ")
              ])
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.REQUIRE_LOGIN == 1)
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: 0,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFavor(_ctx.currentI)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(['i-favor', { active: _ctx.currentI.favor }])
                  }, null, 2 /* CLASS */),
                  (_ctx.currentI.favor)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, "取消收藏"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, "收藏"))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createTextVNode(" 操作"),
        _createVNode(_component_el_icon, { class: "el-icon--right" }, {
          default: _withCtx(() => [
            _createVNode(_component_arrow_down)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}