
import { defineComponent, ref, watch } from 'vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  name: '属性信息',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    IData.value = props.data

    watch(
      () => props.data,
      () => {
        IData.value = props.data
      },
      {
        deep: true
      }
    )
    return {
      IData
    }
  }
})
