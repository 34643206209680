
import { defineComponent } from 'vue'
import leftPane from './cpns/leftPane/leftPane.vue'
import BreadCrumb from '@/components/breadCrumb/index.vue'
import rightPane from './cpns/rightPane/index.vue'

export default defineComponent({
  name: 'SearchList',
  components: {
    leftPane,
    rightPane,
    BreadCrumb
  },
  setup() {
    return {}
  }
})
